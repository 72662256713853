import React, { useEffect, useState } from 'react';

import CompanyProfile from './CompanyProfile';
import PersonalProfile from './PersonalProfile';
import Header from '../Header';
import Footer from '../Footer';

import styles from './Profile.module.scss';
import AdminCard from '../Administrators/AdminCard';
import Loader from '../../../core-components/Loader';

export default (props) => {
  const [editAdminData, setEditAdminData] = useState(null);
  const [loading, setLoading] = useState(false);
  const profileInfo = props.profile.profile;

  const createAdminData = () => {
    const tempAdminData = {
      accessLevel: profileInfo.access_level,
      id: profileInfo.id,
      name: profileInfo.name,
      email: profileInfo.email,
      mobile: profileInfo.mobile,
      userEmailRejectType: profileInfo.user_email_reject_type
    };
    setEditAdminData(tempAdminData);
  };

  const showAdminCardHandler = () => {
    createAdminData();
    props?.history?.push({
      pathname: '/myTeam'
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    createAdminData();
    props?.getPaymentDetails();
  }, []);

  return (
    <div className={styles.profileWrapper}>
      {/* <div className={styles.profileHeading}>{'Profile'}</div> */}
      {!loading ? (
        <AdminCard
          title={'Edit User'}
          editAdminData={editAdminData}
          isEditAdmin={true}
          showAdminCardHandler={showAdminCardHandler}
        />
      ) : (
        <Loader />
      )}
      {/* Ticket number ADO-1747
        <div className={styles.cardMain}>
          <CompanyProfile {...props} />
          <PersonalProfile {...props} />
        </div> */}
    </div>
  );
};
