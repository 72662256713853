// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sab3huZjCbcTO1HVPguU {
  display: flex;
}

.bpme1aFDzRlTCBUBlN0c {
  width: 150px !important;
  position: relative;
  left: -50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.VOHmM4widrUq5BhBMwqX {
  height: fit-content !important;
}

.I2SBitKFISKrAglIdRmn {
  font-size: 11px !important;
  color: #666666 !important;
  display: flex !important;
  margin-top: 5px !important;
  padding-inline: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  width: fit-content !important;
}

.TPI_u59GtaVqaOQ82mgM {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.mVkq8KwLec2ErMzUzRy1 {
  margin-right: 24px;
}

.sab3huZjCbcTO1HVPguU {
  width: 100%;
  height: 100%;
}

.ZAZZYcwavMJLZ31mxfOg {
  width: 48px;
  height: 48px;
  transition: transform 0.2s ease-in-out;
}
.ZAZZYcwavMJLZ31mxfOg:hover {
  transform: scale(1.1);
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/Banners/UpsellBanner/components/UpsellCarousel/UpsellCarousel.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,uBAAA;EACA,kBAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE,8BAAA;AACF;;AAEA;EACE,0BAAA;EACA,yBAAA;EACA,wBAAA;EACA,0BAAA;EACA,oBAAA;EACA,gBAAA;EACA,mBAAA;EACA,6BAAA;AACF;;AAEA;EACE,aAAA;EACA,WAAA;EACA,8BAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,sCAAA;AACF;AAAE;EACA,qBAAA;AAEF","sourcesContent":[".container {\n  display: flex;\n}\n\n.checkNameContainer {\n  width: 150px !important;\n  position: relative;\n  left: -50px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.swiperStyles {\n  height: fit-content !important;\n}\n\n.checkName {\n  font-size: 11px !important;\n  color: #666666 !important;\n  display: flex !important;\n  margin-top: 5px !important;\n  padding-inline: 10px;\n  padding-top: 2px;\n  padding-bottom: 2px;\n  width: fit-content !important;\n}\n\n.marquee {\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n}\n\n.icon {\n  margin-right: 24px;\n}\n\n.container {\n  width: 100%;\n  height: 100%;\n}\n\n.checkIcon {\n  width: 48px;\n  height: 48px;\n  transition: transform 0.2s ease-in-out; \n  &:hover {\n  transform: scale(1.1);\n}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `sab3huZjCbcTO1HVPguU`,
	"checkNameContainer": `bpme1aFDzRlTCBUBlN0c`,
	"swiperStyles": `VOHmM4widrUq5BhBMwqX`,
	"checkName": `I2SBitKFISKrAglIdRmn`,
	"marquee": `TPI_u59GtaVqaOQ82mgM`,
	"icon": `mVkq8KwLec2ErMzUzRy1`,
	"checkIcon": `ZAZZYcwavMJLZ31mxfOg`
};
export default ___CSS_LOADER_EXPORT___;
